<template>
  <div>
    <header :style="headerStyle">
      <div class="nav-top">
        <div class="nav">
          <a
            href="/"
            class="logo"
          >

            <img
              src="../assets/img/logo.png"
              alt="logo"
            >
          </a>
          <div class="nav-list">
            <router-link
              to="/"
              class="nav-item"
            >
              <span class="label">首页</span>
            </router-link>
            <router-link
              to="/recruit"
              class="nav-item"
            >
              <span class="label">人才测评</span>
            </router-link>
            <router-link
              to="/productService"
              class="nav-item"
            >
              <span class="label">人才管理</span>
            </router-link>
            <el-popover
              placement="top-start"
              trigger="hover"
            >
              <div class="list-nav">
                <router-link
                  to="/community"
                  class="list-item"
                  slot="reference"
                >
                  社会招聘
                </router-link>
                <router-link
                  to="/jobAdjustment"
                  class="list-item"
                  slot="reference"
                >
                  岗位调整
                </router-link>
                <router-link
                  to="/topManager"
                  class="list-item"
                  slot="reference"
                >
                  中高管招聘
                </router-link>
                <router-link
                  to="/solution"
                  class="list-item"
                  slot="reference"
                >
                  内部选拔晋升
                </router-link>

              </div>
              <div
                to="/solution"
                class="nav-item"
                slot="reference"
              >
                <span class="label">场景解决方案 <i class="el-icon-arrow-down"></i></span>
              </div>
            </el-popover>

            <!-- <router-link
            to="/productService"
            class="nav-item"
          >
            <span class="label">新闻资讯</span>
          </router-link> -->
            <router-link
              to="/about"
              class="nav-item"
            >
              <span class="label">关于我们</span>
            </router-link>
          </div>
        </div>
        <router-link
          to="/applyFor"
          class="ri-btn"
        >联系我们</router-link>
        <img
          class="ri-img"
          src="../assets/img/move/caidan.png"
          alt=""
          @click="open"
        >

      </div>
    </header>
    <el-drawer
      :visible.sync="drawer"
      direction="ttb"
      size="100%"
      :withHeader="false"
      :show-close='false'
    >
      999
      <div class="drawer-box">
        <div class="top">
          <a
            href="/"
            class="logo"
          >

            <img
              src="../assets/img/logo.png"
              alt="logo"
            >

          </a>
          <img
            @click="close"
            src="../assets/img/move/close.png"
            alt="close"
            class="close"
          >
        </div>
        <div class="dra-list">
          <div
            class="dra-item"
            v-for="item,index in draList"
            :key="index"
            @click="handelOpenItem(index)"
          >

            <router-link
              :to="item.link"
              class="name"
            >
              <div class="na">{{item.name}}</div>
              <img
                v-if="item.list"
                :src="iSchild? require('../assets/img/move/jiantoushang.png'): require('../assets/img/move/jiantouxia.png')"
                alt=""
              >
            </router-link>
            <div @click.stop=''>
              <transition
                class=""
                name="slide"
              >
                <div
                  class="child-box"
                  v-if="iSchild"
                  @click="handelOpenChildItem"
                >
                  <router-link
                    class="child "
                    :to="i.link"
                    v-for="i,t in item.list"
                    :key="t"
                  >{{i.name}}</router-link>
                </div>
              </transition>
            </div>

          </div>

        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      iSchild: false,
      drawerClass: 'animate__animated animate__fadeInDown',
      headerStyle: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      },
      draList: [
        {
          name: '人才测评',
          link: "/recruit"
        },
        {
          name: '人才管理',
          link: "/productService"
        },
        {
          name: '场景解决方案',
          link: 'jobAdjustmentjobAdjustment',
          list: [
            {
              name: '岗位调整',
              link: "/jobAdjustment"
            },
            {
              name: '内部选拔晋升',
              link: "/solution"
            },
            {
              name: '社会招聘',
              link: "/community"
            },
            {
              name: '中高管招聘',
              link: "/topManager"
            },
          ]
        },
        // {
        //   name: '新闻资讯',
        //   link: "/productService",
        // },
        {
          name: '关于我们',
          link: "/about",
        }



      ]
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 0) {
        this.headerStyle.backgroundColor = 'white';
      } else {
        this.headerStyle.backgroundColor = 'rgba(255, 255, 255, 0.5)';
      }
    },
    scrollToBottom() {

      // 滚动到底部元素的位置
      window.scrollTo(0, 50000);

    },
    open() {
      this.drawerClass = 'animate__animated animate__fadeInDown'
      this.drawer = true
    },
    close() {

      this.drawer = false

    },
    handelOpenItem(index) {
      if (index == 2) {
        this.iSchild = !this.iSchild
      }
      else {

        this.drawer = false
      }

    },
    handelOpenChildItem() {

      this.drawer = false

    },
  },

};
</script>


<style scoped lang="less">

header {
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 56px;
  line-height: 56px;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 auto;
  color: #22262d;
  .nav-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1440px;
    margin: 0 auto;
    .nav {
      display: flex;

      .logo {
        margin-top: 17px;
        img {
          width: 167px;
          height: 22px;
          object-fit: cover;
        }
      }

      .nav-list {
        display: flex;
        align-items: center;

        .nav-item {
          font-weight: 500;
          font-size: 13px;
          color: #22262d;
          margin-left: 32px;
          cursor: pointer;
        }
      }
    }

    .ri-btn {
      font-weight: 500;
      font-size: 13px;
      color: #ffffff;
      line-height: 18px;
      width: 94px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      background: #3e75f7;
      border-radius: 17px;
      cursor: pointer;
    }
    .ri-img {
      display: none;
    }
  }
}
.list-item {
  display: block;
  padding: 4px;
  font-weight: 500;
  font-size: 13px;
  color: #22262d;
  &:hover {
    background: #f5f5f5;
  }
}
.drawer-box {
  display: none;
}

@media screen and (max-width: 750px) {
  header {
    color: #22262d;
    height: calc(44 / 375 * 100vw);
    .nav-top {
      width: 100%;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: calc(14 / 375 * 100vw) calc(16 / 375 * 100vw);

      .nav {
        .logo {
          margin-top: 0;
          img {
            width: calc(130 / 375 * 100vw);
            height: calc(16 / 375 * 100vw);
            object-fit: cover;
          }
        }

        .nav-list {
          display: none;
        }
      }

      .ri-btn {
        font-weight: 400;
        font-size: calc(12 / 375 * 100vw);
        color: #ffffff;
        line-height: calc(24 / 375 * 100vw);
        text-align: center;
        background: #3e75f7;
        border-radius: 17px;
        cursor: pointer;
        width: calc(78 / 375 * 100vw);
        height: calc(24 / 375 * 100vw);
        background: #3e75f7;
        border-radius: calc(17 / 375 * 100vw);
        margin-left: calc(95 / 375 * 100vw);
      }
      .ri-img {
        display: block;
        width: calc(24 / 375 * 100vw);
        height: calc(24 / 375 * 100vw);
        object-fit: cover;
      }
    }
  }
  /deep/.v-modal {
    background: #fff !important;
  }
}
.drawer-box {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 999;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    .logo {
      img {
        width: 130px;
      }
    }
    .close {
      width: calc(24 / 375 * 100vw);
      height: calc(24 / 375 * 100vw);
    }
  }
  .dra-list {
    padding: calc(14 / 375 * 100vw) calc(16 / 375 * 100vw);
    .dra-item {
      display: block;
      box-sizing: border-box;
      font-size: calc(16 / 375 * 100vw);
      color: #22262d;
      .name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        padding:calc(14 / 375 * 100vw) calc(16 / 375 * 100vw);

        .na {
          flex: 1;
        }
        img {
          width: calc(16 / 375 * 100vw);
        }
      }
      .child-box {
        padding: 0 calc(16 / 375 * 100vw);
        .child {
          font-weight: 400;
          padding: calc(14 / 375 * 100vw) 0  calc(14 / 375 * 100vw) calc(16 / 375 * 100vw) ;
        }
      }
    }
    .slide-enter-active,
    .slide-leave-active {
      transition: all 0.5s ease;
    }
    .slide-enter,
    .slide-leave-to {
      max-height: 0;
      overflow: hidden;
    }
    .slide-enter-to,
    .slide-leave {
      max-height: calc(200 / 375 * 100vw); /* 根据内容调整高度 */
      overflow: hidden;
    }
    .router-link-active,
    .router-link-exact-active {
      background: #f1f6ff;
      height: calc(50 / 375 * 100vw);
      color: #1b67ff;
      border-radius: 4px;
    }
  }
}
</style>

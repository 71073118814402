// import * as LoginApi from '@/api/login'
import storage from '@/utils/storage'

export default {
  state: {
    token: storage.get('token') || ''
  },
  mutations: {
    SET_TOKEN: (state, value) => {
      storage.set('token', value)
      state.token = value
    }
  },
  actions: {
    Login ({ commit }, data) {
      return new Promise((resolve, reject) => {
        LoginApi.login({ form: data })
          .then(response => {
            if (response.status === 200) {
              commit('SET_TOKEN', response.data.token)
              resolve(response)
            } else {
              // reject(response)
            }
          })
          .catch(reject)
      })
    },
    LogOut ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '')
        resolve()
      })
    }
  },
  getters: {
    getToken (state) {
      return state.token
    }
  }
}

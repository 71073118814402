import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/index.vue'),
    meta: {
      isFooter: true
    },

  },
  {
    path: '/productService',
    name: 'productService',
    component: () => import(/* webpackChunkName: "about" */ '../views/productService/index.vue'),
    meta: {
      isFooter: true
    },
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: () => import(/* webpackChunkName: "about" */ '../views/recruit/index.vue'),
    meta: {
      title: '千寻智聘',
      footerTitle: '千寻智聘人才测评，让优秀人才不再难寻',
      isFooter: true
    },
  },
  {
    path: '/solution',
    name: 'solution',
    component: () => import(/* webpackChunkName: "about" */ '../views/solution/index.vue'),
    meta: {
      isFooter: true
    },

  },
  {
    path: '/jobAdjustment',
    name: 'jobAdjustment',
    component: () => import(/* webpackChunkName: "about" */ '../views/solution/jobAdjustment.vue'),
    meta: {
      isFooter: true
    },
  },
  {
    path: '/community',
    name: 'community',
    component: () => import(/* webpackChunkName: "about" */ '../views/solution/community.vue'),
    meta: {
      isFooter: true
    },
  },

  {
    path: '/topManager',
    name: 'topManager',

    component: () => import(/* webpackChunkName: "about" */ '../views/solution/topManager.vue'),
    meta: {
      isFooter: true
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/index.vue'),
    meta: {
      title: '关于',
      isFooter: true
    },
  },
  {
    path: '/applyFor',
    name: 'applyFor',
    component: () => import(/* webpackChunkName: "about" */ '../views/applyFor/index.vue'),
    meta: {
      title: '申请咨询',
      isFooter: false
    },
  },
  {
    path: '/news',
    name: 'newsInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/news/index.vue'),
    meta: {
      title: '隐私协议',
      isFooter: false
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes,
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

export default router

<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false"
      width="375px"
      top="2vh"
    >
      <div class="code">
        <img
          class="close-icon"
          @click="dialogVisible=false"
          src="@/assets/img/code-close-icon.png"
          alt=""
        >
        <img
          class="code-img"
          src="@/assets/img/code.png"
          alt=""
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },

  methods: {
    open() {
      this.dialogVisible = true;

    }
  },
  mounted() {

  }
};
</script>
<style lang="less" scoped>
/deep/.el-dialog {
  background: transparent;
  box-shadow: none;
}
.code {
  position: relative;
  width: 433px;
  height: 770px;
  background: url("@/assets/img/code-bac.png") no-repeat;
  background-size: cover;
  margin: auto;
  .close-icon {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  .code-img {
    width: 194px;
    height: 194px;
    position: absolute;
    bottom: 194px;
    left: 120px;
  }
}
@media screen and (max-width: 750px) {
  .code {
    position: relative;
    width: 368px;
    height: 654px;
    background: url("@/assets/img/code-bac.png") no-repeat;
    background-size: cover;
    overflow: hidden;
    margin-left: -18px;
    .close-icon {
      width: 50px;
      height: 50px;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
    .code-img {
      width: 164.9px;
      height: 164.9px;
      position: absolute;
      bottom: 163px;
      left: 101px;
    }
  }
}
</style>

